//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { validationMixin } from "vuelidate";
import { GET_USER_LEVEL, GET_USER_LEVEL_LIST } from "@/graphql/admin/user_level";
export default {
  mixins: [validationMixin],
  props: ["data", "onChange"],
  data: function data() {
    var _this$data, _this$data2;

    return {
      nameUserLevelState: null,
      user: {
        userId: (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.userId,
        userLevelId: (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : _this$data2.userLevelId
      }
    };
  },
  validations: {
    user: {
      userLevelId: {}
    }
  },
  watch: {
    data: function data(newVal) {
      this.user.userLevelId = newVal.userLevelId;
      this.user.userId = newVal.userId;
    }
  },
  apollo: {
    systemUserLevelList: {
      query: GET_USER_LEVEL_LIST
    },
    systemUserLevel: {
      query: GET_USER_LEVEL,
      skip: function skip() {
        return !this || !this.user || !this.user.userLevelId;
      },
      variables: function variables() {
        return {
          id: this.user && this.user.userLevelId
        };
      }
    }
  }
};